@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600&family=Noto+Serif:ital@0;1&display=swap');

*, *::before, *::after {
    margin: 0;
    box-sizing: border-box;
    padding: 0;
}

body {
    font-family: $noto-sans;
    background-color: white;
}

