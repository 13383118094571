@import '../../styles/variables.scss';

.hero {
    padding: 25% 10% 10% 5%;
    .container-hero {
        border-bottom: 1px solid #000;
        padding-bottom: 20px;
        .regular {
            font-size: 1.3rem;
            font-family: $noto-serif;
        }

        .italic {
            font-size: 1.3rem;
            font-family: $noto-serif;
            font-style: italic;
        }
    }
}

@media only screen and (min-width: 420px) {
    .hero {
        padding: 6% 5% 3%;
        .container-hero {
            padding-bottom: 35px;
            .regular {
                font-size: 3rem;
            }
    
            .italic {
                font-size: 3rem;
            }
        }
    }
}