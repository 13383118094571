$noto-sans: 'Noto Sans', sans-serif;
$noto-serif: 'Noto Serif', serif;

$primary-color: #fff;
$secondary-color: #aac5d6;
$text-color: #000;
$dark-color: #000;
$gray-color: #A4A4A4;

$spacing-sm: 8px;
$spacing-md: 12px;
$spacing-lg: 32px;

$header-height: 72px;

$sm: 37.5em;
$md: 48em;
$lg: 64em;
$xl: 75em;

@mixin breakpoint($point) {
    @if $point == md {
        @media (min-width: $md) {
            @content;
        }
    }
}